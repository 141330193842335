import React from 'react';
import styled from 'styled-components';

import { ExternalLink } from './link';

const StyledFooter = styled.footer`
  padding: 2rem 0;

  @media (max-width: 37.5em) {
    padding: 1rem 0;
  }
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <ExternalLink href="https://www.github.com/sean-beard" hideArrow>
        GitHub
      </ExternalLink>
      &nbsp; &#8226; &nbsp;
      <ExternalLink href="https://www.twitter.com/automasean_" hideArrow>
        Twitter
      </ExternalLink>
      &nbsp; &#8226; &nbsp;
      <ExternalLink href="https://stackoverflow.com/users/11116802/automasean" hideArrow>
        Stack Overflow
      </ExternalLink>
    </StyledFooter>
  );
};
