import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../styles/base';

const Container = styled.header`
  margin-bottom: 2rem;
  padding-top: 1.45rem;

  > h1,
  > h2 {
    margin: 0;
  }
`;

const HeaderPageLink = styled(Link)`
  color: ${({ secondary }) =>
    secondary ? `${colors.SUN_FLOWER}` : `${colors.LEMON_LIME}`};
  text-decoration: none;
`;

const Header = ({ siteTitle, secondary }) => {
  const LinkToHome = () => (
    <HeaderPageLink to="/" {...{ secondary }}>
      {siteTitle}
    </HeaderPageLink>
  );

  return (
    <Container>
      {secondary ? (
        <h2>
          <LinkToHome />
        </h2>
      ) : (
        <h1>
          <LinkToHome />
        </h1>
      )}
    </Container>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  secondary: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
  secondary: false,
};

export default Header;
