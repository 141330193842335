import { createGlobalStyle } from 'styled-components';

import { colors } from './base';
import { linkStyles } from '../components/link';

const GlobalStyles = createGlobalStyle`
  html {
    font-family: Arial, sans-serif;
    font-size: 1.33em;
  }

  body {
    background: ${colors.OLIVE};
    color: ${colors.NEAR_WHITE};
  }

  h1 {
    color: ${colors.LEMON_LIME};
  }

  h2,
  h3,
  h4,
  h5,
  em {
    color: ${colors.LEMON_LIME};
    margin: 1.5em 0 .5em 0;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p {
    margin-top: 0;
  }

  blockquote {
    color: ${colors.LEMON_LIME};
  }

  a {
    ${linkStyles};
  }

  hr {
    border-color: ${colors.LEMON_LIME};
    margin: 2rem 0;
  }

  p {
    line-height: 1.5;
  }

  strong {
    color: ${colors.SUN_FLOWER};
  }

  ol > li:not(:last-of-type),
  ul > li:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  ol ul,
  ul ol,
  ol ol,
  ul ul {
    margin-top: 1rem;
  }

  sup {
    vertical-align: top;
    position: relative;
    top: -0.25em;
  }

  .footnotes {
    overflow-wrap: break-word;
  }
  
  .footnote-ref {
    font-weight: bold;
    ${linkStyles}
  }

  code {
    color: ${colors.LEMON_LIME};
    font-size: 1.25em;
  }

  code[class*='language-'],
  pre {
    background: none;
    font-family: Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace;
    font-feature-settings: normal;
    font-size: 18px;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    margin-bottom: 0;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  /* Code blocks */
  pre[class*='language-'] {
    overflow: auto;
    padding: 0.5rem;
  }

  @media (max-width: 672px) {
    code {
      color: ${colors.LEMON_LIME};
      font-size: 1.25em;
    }

    pre[class*='language-'] {
      overflow: auto;
      padding: 0;
      font-weight: bold;
  }
}
`;

export default GlobalStyles;
